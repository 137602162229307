import React from "react"
import styled from "styled-components"

import ThemeContextConsumer from "../../../data/ThemeContext"
import { hex2rgba } from "../../../styles/tools"

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 900px) {
    right: initial;
    left: 0rem;
    top: calc(80px + 4rem);

    align-items: flex-start;
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  padding: 0.325rem 0 0.325rem 0.5rem;

  font-family: ${props => props.theme.font.heading};
  font-size: 1.375em;
  color: ${props => hex2rgba(props.theme.color.primary, 0.5)};
  transition: color 0.2s ease-in-out;

  outline: none;

  border: none;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 540px) {
    font-size: 1.2em;
    margin: 0 0 0.75rem;
  }

  &[data-active="true"] {
    color: ${props => hex2rgba(props.theme.color.primary, 1)};
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .label {
    position: relative;
    z-index: 2;

    display: block;
    padding: 0.2rem 0.2rem 0.1rem;
    order: 2;

    border-radius: 4px;
    background-color: ${props => props.theme.color.lightBg};
    transition: background 0.2s ease-in-out;
  }

  .dot {
    position: relative;
    z-index: 1;

    order: 3;

    margin-left: 0.75rem;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;

    border-radius: 2rem;

    @media (max-width: 900px) {
      order: 1;
    }
  }

  .dot-core {
    position: relative;
    z-index: 2;

    display: block;
    width: 1rem;
    height: 1rem;

    border-radius: 2rem;

    background-color: ${props => hex2rgba(props.theme.color.primary, 0.5)};
    transition: all 0.2s ease-in-out;
  }
  &[data-active="true"] .dot-core {
    background-color: ${props => hex2rgba(props.theme.color.primary, 1)};
  }

  .dot-ring {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: block;
    width: 1rem;
    height: 1rem;

    opacity: 0.66;
    border: 0 solid ${props => props.theme.color.darkBg};
    border-radius: 100%;
    transition: all 0.05s cubic-bezier(0.18, 0.89, 0.47, 1.26);
  }

  &[data-active="true"] .dot-ring {
    border-width: 3px;

    transition-duration: 0.3s;

    &[data-index="1"] {
      width: 2rem;
      height: 2rem;

      transition-delay: 0.1s;
    }
    &[data-index="2"] {
      width: 3rem;
      height: 3rem;

      transition-delay: 0.2s;
    }
    &[data-index="3"] {
      width: 4rem;
      height: 4rem;

      transition-delay: 0.3s;
    }
  }
`

const Message = styled.div`
  position: relative;

  padding: 1.25em;
  width: 260px;
  max-width: 100%;

  font-weight: bold;
  color: ${props => props.theme.color.primary};

  border: 3px solid ${props => props.theme.color.primary};
  border-radius: 1rem;
  background-color: ${props => props.theme.color.lightBg};

  transition: all 0.2s ease-in-out;

  @media (max-width: 900px) {
    display: none;
  }
`
Message.arrow = styled.div`
  position: absolute;
  bottom: 100%;
  right: 2rem;

  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid ${props => props.theme.color.primary};

  transform-origin: center bottom;
  transition: all 0.2s ease-in-out;

  &::after {
    position: absolute;
    left: 50%;
    top: 4px;
    transform: translateX(-50%);

    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid ${props => props.theme.color.lightBg};
    content: "";
    transition: border-color 0.2s ease-in-out;
  }
`

const Dot = () => {
  return (
    <span className="dot">
      <span className="dot-core" />
      <span className="dot-ring" data-index={1} />
      <span className="dot-ring" data-index={2} />
      <span className="dot-ring" data-index={3} />
    </span>
  )
}

export default function ThemeSwitcher(props) {
  return (
    <Wrapper>
      <ThemeContextConsumer>
        {({ data, set }) => (
          <>
            <Button
              data-active={data.themeName === "energy-freezes"}
              onClick={e => set({ themeName: "energy-freezes" })}
            >
              <span className="label">Energy Freezes</span>
              <Dot />
            </Button>
            <Button
              data-active={data.themeName === "milkshakes"}
              onClick={e => set({ themeName: "milkshakes" })}
            >
              <span className="label">Milkshakes</span>
              <Dot />
            </Button>
            <Button
              data-active={data.themeName === "smoothies"}
              onClick={e => set({ themeName: "smoothies" })}
            >
              <span className="label">Smoothies</span>
              <Dot />
            </Button>
          </>
        )}
      </ThemeContextConsumer>
    </Wrapper>
  )
}
